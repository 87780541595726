import React from "react";
import katex from "katex";
import "katex/dist/katex.min.css";
import "react-quill/dist/quill.bubble.css";
// import ReactMarkdown from "react-markdown/with-html";
window.katex = katex;

const QuillBubble = (props) => {
  const { contents } = props;
  return (
    <div className="ql-container ql-bubble ql-disabled">
      <div
        className="ql-editor"
        data-gramm="false"
        dangerouslySetInnerHTML={{ __html: contents }}
      ></div>
    </div>
  );
};

export default QuillBubble;
