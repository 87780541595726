/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardSubjectAnalytics from './views/DashboardSubjectAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import EmailSetting from 'views/EmailSetting';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/overview" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path :"/users/students",
        exact : true,
        component : lazy(() => import('views/StudentList'))
      },
      {
        path: '/studentDetail/:studentId',
        exact: true,
        component: lazy(() => import('views/StudentDetail'))
      },
      {
        path: '/studentDetail/:studentId/:tab',
        exact: true,
        component: lazy(() => import('views/StudentDetail'))
      },
      {
        path :"/users/studentsUnset",
        exact : true,
        component : lazy(() => import('views/StudentListUnset'))
      },
      {
        path :"/users/tutors",
        exact : true,
        component : lazy(() => import('views/TutorList'))
      },
      
      {
        path :"/users/tutorsUnset",
        exact : true,
        component : lazy(() => import('views/TutorListUnSet'))
      },
      {
        path: '/tutorDetail/:tutorId',
        exact: true,
        component: lazy(() => import('views/TutorDetail'))
      },
      {
        path: '/tutorDetail/:tutorId/:tab',
        exact: true,
        component: lazy(() => import('views/TutorDetail'))
      },
      
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/subject',
        exact: true,
        component: DashboardSubjectAnalytics 
      },
      {
        path: '/emailSetting/:type',
        exact: true,
        component: EmailSetting
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path :"/postList",
        exact : true,
        component : lazy(() => import('views/PostList'))
      },
      {
        path :"/postCreate/",
        exact : true,
        component : lazy(() => import('views/PostCreate'))
      },
      {
        path :"/postEdit/:postId",
        exact : true,
        component : lazy(() => import('views/PostEdit'))
      },
      {
        path :"/postView/:postId",
        exact : true,
        component : lazy(() => import('views/PostView'))
      },
      {
        path :"/aboutUs/",
        exact : true,
        component : lazy(() => import('views/AboutUs'))
      },
      {
        path :"/aboutEdit/",
        exact : true,
        component : lazy(() => import('views/AboutUsEdit'))
      },
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('views/GettingStarted'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
