import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { colors } from '@material-ui/core';
import { Chart } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    padding: theme.spacing(3),
    flexGrow: 1,
    
      borderRight: `1px solid ${theme.palette.divider}`
  },
  content: {
    padding: 0
  },
  date: {
    whiteSpace: 'nowrap'
  }
}));


const CategorySegmentation = props => {
  const {categoryCount, totalCount, className, ...rest } = props;

  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
  if(categoryCount !== null){
    setCategories([
        {
          id: 1,
          label: 'Math',
          value: (categoryCount["Math"] / totalCount * 100).toFixed(1),
          count: categoryCount["Math"],
          color: colors.blue[500]
        },
        {
          id: 2,
          label: 'Science',
          value: (categoryCount["Science"] / totalCount * 100).toFixed(1),
          count: categoryCount["Science"],
          color: colors.green[500]
        },
        {
          id: 3,
          label: 'English',
          value: (categoryCount["English"] / totalCount * 100).toFixed(1),
          count: categoryCount["English"],
          color: colors.red[500]
        },
        {
          id: 4,
          label: 'Language',
          value: (categoryCount["Language"] / totalCount * 100).toFixed(1),
          count: categoryCount["Language"],
          color: colors.brown[500]
        },
        {
          id: 5,
          label: 'Test Prep',
          value: (categoryCount["Test Prep"] / totalCount * 100).toFixed(1),
          count: categoryCount["Test Prep"],
          color: colors.indigo[500]
        },
      ]);
  }
}, [categoryCount]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Category Segmentation" />
      <Divider />
      <CardContent className={classes.content}>
      <div className={classes.chartContainer}>
          <Chart
            className={classes.chart}
            data={categories}
          />
        </div>
     
        <Divider />
        <div className={classes.statsContainer}>
          {categories.map(earning => (
            <div
              className={classes.statsItem}
              key={earning.id}
            >
              <Typography
                align="center"
                component="h6"
                gutterBottom
                variant="overline"
              >
                {earning.label}
              </Typography>
              <Typography
                align="center"
                variant="h3"
              >
                {earning.count}
              </Typography>
              <Typography
                align="center"
                variant="h5"
              >
                {earning.value} %
              </Typography>
           
            </div>
          ))}
        </div>
        <Divider />
      </CardContent>
    </Card>
  );
};

CategorySegmentation.propTypes = {
  className: PropTypes.string
};

export default CategorySegmentation;
