import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Typography,
  Grid,
  colors,
  CardHeader,
  Divider,
  CardContent
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { ReadCounts } from '../../../../actions/overViewAction';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 10
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    padding: theme.spacing(3),
    flexGrow: 1,
    '&:first-of-type': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },

  date: {
    whiteSpace: 'nowrap'
  }
}));

const Statistics = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const overView = useSelector(state => state.overView);
  useEffect(() => {
    dispatch({
      type: 'START_OVERVIEW'
    });

    dispatch(ReadCounts());
  }, []);


  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid className={classes.item} item md={3} sm={6} xs={12}>
            <Typography variant="h2">
              {overView && overView.counts.totalStudents}
            </Typography>
            <Typography className={classes.overline} variant="overline">
              Total Students
            </Typography>
          </Grid>
          <Grid className={classes.item} item md={3} sm={6} xs={12}>
            <Typography variant="h2">
              {overView && overView.counts.totalTutors}
            </Typography>
            <Typography className={classes.overline} variant="overline">
              Total Tutors
            </Typography>
          </Grid>
          <Grid className={classes.item} item md={3} sm={6} xs={12}>
            <Typography variant="h2">
              {overView.counts &&
                overView.counts.onlineTutors}
            </Typography>
            <Typography className={classes.overline} variant="overline">
              Online Tutors
            </Typography>
          </Grid>
          <Grid className={classes.item} item md={3} sm={6} xs={12}>
            <div className={classes.titleWrapper}>
              <Typography component="span" variant="h2">
                {overView &&
                  overView.counts.onlineStudents}
              </Typography>
            </div>
            <Typography className={classes.overline} variant="overline">
              Online Students
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={6} xs={12}>
          <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader title="Today (visitors & signup users)" />
            <Divider />
            <div className={classes.statsContainer}>
              <div className={classes.statsItem}>
             
                <Typography
                  align="center"
                  component="h4"
                  gutterBottom
                  variant="overline">
                  Tutor Visitors
                </Typography>
                <Typography align="center" variant="h3">
                {overView.counts && overView.counts.todayTutorVisitor}
                </Typography>
                
              </div>
              <Divider />
              <div className={classes.statsItem}>
                <Typography
                  align="center"
                  component="h4"
                  gutterBottom
                  variant="overline">
                  Student Visitors
                </Typography>
                <Typography align="center" variant="h3">
                {overView.counts && overView.counts.todayStudentVisitor }
                </Typography>
              </div>
            </div>
            <Divider />
            <div className={classes.statsContainer}>
              <div className={classes.statsItem}>
                <Typography
                  align="center"
                  component="h4"
                  gutterBottom
                  variant="overline">
                  Tutor SignUp users
                </Typography>
                <Typography align="center" variant="h3">
                {overView &&
                  overView.counts.todayTutorSignup }
                </Typography>
              </div>
              <Divider />
              <div className={classes.statsItem}>
                <Typography
                  align="center"
                  component="h4"
                  gutterBottom
                  variant="overline">
                  Student Sign up Users
                </Typography>
                <Typography align="center" variant="h3">
                {overView && 
                    overView.counts.todayStudentSignup}
                </Typography>
              </div>
            </div>
            <Divider />
            <CardContent className={classes.content}></CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader title="Yesterday (visitors & signup users)" />
            <Divider />
            <div className={classes.statsContainer}>
              <div className={classes.statsItem}>
                <Typography
                  align="center"
                  component="h4"
                  gutterBottom
                  variant="overline">
                  Tutor Visitors
                </Typography>
                <Typography align="center" variant="h3">
                {overView.counts && overView.counts.yesterdayTutorVisitor}
                </Typography>
              </div>
              <Divider />
              <div className={classes.statsItem}>
                <Typography
                  align="center"
                  component="h4"
                  gutterBottom
                  variant="overline">
                  Student Visitors
                </Typography>
                <Typography align="center" variant="h3">
                {overView.counts && overView.counts.yesterdayStudentVisitor }
                </Typography>
              </div>
            </div>
            <Divider />
            <div className={classes.statsContainer}>
              <div className={classes.statsItem}>
                <Typography
                  align="center"
                  component="h4"
                  gutterBottom
                  variant="overline">
                  Tutor SignUp users
                </Typography>
                <Typography align="center" variant="h3">
                {overView &&
                  overView.counts.yesterdayTutorSignup }
                </Typography>
              </div>
              <Divider />
              <div className={classes.statsItem}>
                <Typography
                  align="center"
                  component="h4"
                  gutterBottom
                  variant="overline">
                  Student Sign up Users
                </Typography>
                <Typography align="center" variant="h3">
                {overView && 
                    overView.counts.yesterdayStudentSignup}
                </Typography>
              </div>
            </div>
            <Divider />
            <CardContent className={classes.content}></CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
