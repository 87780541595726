const validateYouTubeUrl = (url) => {
    if (url != undefined || url != "") {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
      //   console.log(match);
        return match;
        // Do anything for being valid
        // if need to change the url to embed url then use below line
        // $('#videoObject').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1');
      } else {
        // alert('not valid');
        // Do anything for not being valid
        return false;
      }
    }
  };
  export default validateYouTubeUrl;
  