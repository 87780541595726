const initState = {
  counts: {
    totalTutors: 0,
    totalStudents: 0,
    watchingNow : 0,
    todayTutorVisitor: 0,
    todayStudentVisitor: 0,
    todayTutorSignup: 0,
    todayStudentSignup: 0,
    yesterdayTutorVisitor: 0,
    yesterdayStudentVisitor: 0,
    yesterdayTutorSignup: 0,
    yesterdayStudentSignup: 0,
  },
  searching: false,
  error: ''
};

const overViewReducer = (state = initState, action) => {
  switch (action.type) {
    case 'START_OVERVIEW':
      return {
        ...state,
        searching: true,
        counts : {
          totalTutors: 0,
          totalStudents: 0,
          watchingNow : 0,
          todayTutorVisitor: 0,
          todayStudentVisitor: 0,
          todayTutorSignup: 0,
          todayStudentSignup: 0,
          yesterdayTutorVisitor: 0,
          yesterdayStudentVisitor: 0,
          yesterdayTutorSignup: 0,
          yesterdayStudentSignup: 0,
        },
        error: ''
      };

    case 'READ_COUNTS_SUCCESS':
      return {
        ...state,
        error: '',
        searching: false,
        counts: action.payload
      };

    case 'READ_COUNTS_ERROR':
      return {
        ...state,
        counts : {
          totalTutors: 0,
          totalStudents: 0,
          watchingNow : 0,
          todayTutorVisitor: 0,
          todayStudentVisitor: 0,
          todayTutorSignup: 0,
          todayStudentSignup: 0,
          yesterdayTutorVisitor: 0,
          yesterdayStudentVisitor: 0,
          yesterdayTutorSignup: 0,
          yesterdayStudentSignup: 0,
        },
        error: action.payload
      };

    default:
        return state;
  }
};


export default overViewReducer;