import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import firebase from './fbConfig';
import rootReducer from 'reducers';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';
// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = {}) {
  // const middlewares = [thunk.withExtraArgument({ getFirebase, getFirestore })]; // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(firebase) // need if using fire in action with thunk
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
