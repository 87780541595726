import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';

import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';

import { createFirestoreInstance } from 'redux-firestore';
import firebase from './store/fbConfig';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { CircularProgress } from '@material-ui/core';


const history = createBrowserHistory();
const store = configureStore();

const rrfConfig = { userProfile: 'users', useFirestoreForProfile: true }; // react-redux-firebase config

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
};


function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return (
    <div style = {{textAlign:"center",paddingTop:"200px", width:"100%"}}>
      <CircularProgress disableShrink color="primary" />
    </div>
  )
  return children;
}


const App = () => {
  return (
    <StoreProvider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <AuthIsLoaded>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              {/* <CookiesNotification /> */}
              {renderRoutes(routes)}
            </Router>
          </MuiPickersUtilsProvider>
        </AuthIsLoaded>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </StoreProvider>
  );
};

export default App;
