import moment from 'moment';
import axios from 'axios';

export const ReadCountsByDate = payload => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    let authorId = getState().firebase.auth.uid;
    let counts = {
      totalTutors: 0,
      totalStudents: 0,
      ids: [],
      tutorVisitor: [],
      studentVisitor: [],
      tutorSignup: [],
      studentSignup: [],
      subjectList: null
    };

    const startDate = new Date(payload.startDate);
    const endDate = new Date(payload.endDate);

    if (authorId !== undefined) {
      try {
        const snapshot = await firestore
          .collection('users')
          .where('accountType', '==', 'Student')
          .get();
        if (snapshot.empty) {
          counts.totalStudents = 0;
        } else {
          counts.totalStudents = snapshot.docs.length;
        }
        const snapshot2 = await firestore
        .collection('users')
        .where('accountType', '==', 'Tutor')
        .get();
        // const snapshot2 = await firestore.collection('tutorInfor').get();
        
        if (snapshot2.empty) {
          counts.totalTutors = 0;
        } else {
          counts.totalTutors = snapshot2.docs.length;
        }

        const usersStates = await firestore
          .collection('usersStatus')
          .where('createAt', '>=', startDate.getTime())
          .where('createAt', '<=', endDate.getTime())
          .get();

        if (usersStates.empty) {
          counts.tutorVisitor = [];
          counts.studentVisitor = [];
          counts.tutorSignup = [];
          counts.studentSignup = [];
        } else {
          usersStates.forEach(doc => {
            const _idStrs = doc.id.split('-');
            const label = _idStrs[2] + ' / ' + (parseInt(_idStrs[1]) + 1);
            counts.ids.push(label);
            counts.tutorVisitor.push(
              doc.data().TutorVisitor === undefined
                ? 0
                : doc.data().TutorVisitor
            );
            counts.studentVisitor.push(
              doc.data().StudentVisitor === undefined
                ? 0
                : doc.data().StudentVisitor
            );
            counts.tutorSignup.push(
              doc.data().TutorSignUp === undefined ? 0 : doc.data().TutorSignUp
            );
            counts.studentSignup.push(
              doc.data().StudentSignUp === undefined
                ? 0
                : doc.data().StudentSignUp
            );
          });
        }

        dispatch({
          type: 'DASH_ANALYTICS_READ_COUNT_USERS_SUCCESS',
          payload: counts
        });
      } catch (err) {
        dispatch({
          type: 'DASH_ANALYTICS_READ_COUNT_USERS__ERROR',
          payload: err.message
        });
      }
    }
  };
};
