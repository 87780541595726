import React, { useState ,useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Page } from 'components';
import {
  Header,
  UsersStats,
  UsersSegmentation,
} from './components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ReadCountsByDate } from '../../actions/DashboardAnalyticsAction';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    '& > *': {
      height: '100%'
    }
  }
}));

const DashboardAnalytics = () => {
  const classes = useStyles();
  
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const dispatch = useDispatch();
  const analyticsData = useSelector(state => state.dashboardAnalytic)

  useEffect(() => {
    const payload = {
      startDate : moment(startDate).format(),
      endDate : moment(endDate).format()
    }
    dispatch({
      type: 'START_DASH_ANALYTICS'
    });

    dispatch(ReadCountsByDate(payload));
  }, [startDate,endDate])

  return (
    <Page className={classes.root} title="Analytics Dashboard">
      <Header startDate ={startDate} setStartDate = {setStartDate} endDate= {endDate}  setEndDate= {setEndDate}/>
      <Grid className={classes.container} container spacing={3}>
        {/* <Grid item xs={12}>
          <Overview />
        </Grid> */}
        <Grid item lg={8} xl={9} xs={12}>
          <UsersStats analyticsData ={analyticsData}  />
        </Grid>
        <Grid item lg={4} xl={3} xs={12}>
          <UsersSegmentation analyticsData ={analyticsData}/>
        </Grid>
        
      </Grid>
    </Page>
  );
};

export default DashboardAnalytics;
