import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Switch,
  Button,
  colors,
  TextareaAutosize
} from '@material-ui/core';
import QuillEditor from 'components/QuillEditor';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  fullWidth: {
    padding: 5,
    marginTop: 0,
    width: '100%'
  }
}));

const SettingInfoEdit = props => {
  const { open, onClose, onSave, settingInfo, className, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState(null);

  useEffect(() => {
    setFormState(settingInfo);
  }, [settingInfo]);

  if (!open) {
    return null;
  }

  const handleFieldChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));
  };

  const onEditorChange = value => {
    setFormState(formState => ({
      ...formState,
      contents: value
    }));
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form>
          <CardContent>
            <Typography align="center" gutterBottom variant="h3">
              Edit Email & SMS Setting
            </Typography>
            <Grid className={classes.container} container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  You can insert string of {'{{student}}'} or {'{{tutor}}'} for
                  display Student's name or Tutor's name.<br/>
                  Don't change other strings like {"{{topic}}"}. These are predefined characters.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Send Email </Typography>
                <Typography variant="body2">
                  Enabling this will automatically send the email
                </Typography>
                <Switch
                  checked={formState.enableEmail}
                  color="secondary"
                  edge="start"
                  name="enableEmail"
                  onChange={handleFieldChange}
                  value={formState.enableEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email Subject"
                  name="subject"
                  onChange={handleFieldChange}
                  value={formState.subject}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                Contents:
                <QuillEditor
                  htmlValue={formState.contents}
                  placeholder={'Please insert your question'}
                  onEditorChange={onEditorChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">Send SMS</Typography>
                <Typography variant="body2">
                  Enabling this will automatically send the SMS.
                </Typography>
                <Switch
                  checked={formState.enableSMS}
                  color="secondary"
                  edge="start"
                  name="enableSMS"
                  onChange={handleFieldChange}
                  value={formState.enableSMS}
                />
              </Grid>
              <Grid item xs={12}>
                <span>SMS Text</span>
                <TextareaAutosize
                  className={classes.fullWidth}
                  name="SMS"
                  maxRows={5}
                  minRows={5}
                  onChange={handleFieldChange}
                  value={formState.SMS || ''}
                  aria-label="maximum height"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={onClose} variant="contained">
              Close
            </Button>
            <Button
              className={classes.saveButton}
              onClick={event => onSave(formState)}
              variant="contained">
              Save
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

SettingInfoEdit.displayName = 'SettingInfoEdit';

SettingInfoEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

SettingInfoEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default SettingInfoEdit;
