import axios from "axios";
import * as actions from "./actionTypes";

export const signIn = (credentials) => {
  return (dispatch, getState, {
    getFirebase,
    getFirestore
  }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    if (credentials.rememberme) {
      // keep login state with site close
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(function () {
          return firebase
            .auth()
            .signInWithEmailAndPassword(
              credentials.email,
              credentials.password
            );
        })
        .then(function (result) {
          return firestore
            .collection("users")
            .doc(result.user.uid)
            .update({
              activeState: true
            });
        })

        .catch((err) => {
          dispatch({
            type: "LOGIN_ERROR",
            err
          });
        });
    } else {
      // delete login state when site is closed.
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {
          return firebase
            .auth()
            .signInWithEmailAndPassword(
              credentials.email,
              credentials.password
            );
        })
        .then(function (result) {
          return firestore
            .collection("users")
            .doc(result.user.uid)
            .update({
              activeState: true
            });
        })
        .catch((err) => {
          dispatch({
            type: "LOGIN_ERROR",
            err
          });
        });
    }
  };
};

export const signOut = () => {
  return (dispatch, getState, {
    getFirebase,
    getFirestore
  }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let authorId = getState().firebase.auth.uid;
    firebase
      .auth()
      .signOut()
      .then(() => {
        return firestore
          .collection("users")
          .doc(authorId)
          .update({
            activeState: false,
            lastActive: Date.now()
          });
      })
      .then(() => {
        dispatch({
          type: "SIGNOUT_SUCCESS"
        });
      });
  };
};

export const signUp = (newUser) => {
  return (dispatch, getState, {
    getFirebase,
    getFirestore
  }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let userId = "";
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        userId = resp.user.uid;
        return firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            accountType: newUser.accountType,
            zipcode: newUser.zipcode,
            phoneNumber: newUser.phoneNumber,
            hearAboutUs: newUser.hearAboutUs,
            initials: newUser.firstName[0] + newUser.lastName[0],
            isSetting: false,
          });
      })
      // .then(function (result) {
      //   return axios.post("/api/stripe/createCustomer", {
      //     email: newUser.email,
      //     uid: userId,
      //   });
      // })
      // .then((response) => {
      //   return firestore
      //     .collection("stripe_customers")
      //     .doc(response.data.uid)
      //     .set({
      //       customer_id: response.data.customnerId,
      //       client_secret: response.data.client_secret,
      //     });
      // })
      .then(() => {
        const user = firebase.auth().currentUser;
        user.sendEmailVerification();
        dispatch({
          type: "SIGNUP_SUCCESS"
        });
      })
      .catch((err) => {
        dispatch({
          type: "SIGNUP_ERROR",
          err
        });
      });
  };
};

// Clean up messages
export const clean = () => ({
  type: actions.CLEAN_UP,
});

// Clean up messages
export const setRedirectUrl = (payload) => {
  return () => {
    localStorage.setItem("redirect", JSON.stringify(payload));
  };
};

export const getRedirectUrl = () => {
  const redirectUrl =
    localStorage.getItem("redirect") !== null
      ? JSON.parse(localStorage.getItem("redirect"))
      : null;
  return redirectUrl;
}

export const removeRedirecUrl = () => {
  localStorage.removeItem("redirect");
}

// Verify email actionTypes
export const verifyEmailSend = () => async (
  dispatch,
  getState, {
    getFirebase
  }
) => {
  const firebase = getFirebase();
  dispatch({
    type: actions.VERIFY_EMAIL_SEND_START
  });
  try {
    const user = firebase.auth().currentUser;
    await user.sendEmailVerification();
    dispatch({
      type: actions.VERIFY_EMAIL_SEND_SUCCESS
    });
  } catch (err) {
    dispatch({
      type: actions.VERIFY_EMAIL_SEND_FAIL,
      payload: err.message
    });
  }
};

// Verify email actionTypes
export const ResetPassword = (oobCode, newPassword) => async (
  dispatch,
  getState, {
    getFirebase
  }
) => {
  const firebase = getFirebase();
  dispatch({
    type: actions.VERIFY_START
  });
  firebase
    .auth()
    .confirmPasswordReset(oobCode, newPassword)
    .then(function () {
      // change emailVerified for logged in User
      dispatch({
        type: actions.VERIFY_SUCCESS
      });
    })
    .catch(function (error) {
      dispatch({
        type: actions.VERIFY_FAIL,
        payload: error.message
      });
    });
};

// Verify email actionTypes
export const verifyEmail = (oobCode) => async (
  dispatch,
  getState, {
    getFirebase
  }
) => {
  const firebase = getFirebase();
  dispatch({
    type: actions.VERIFY_START
  });
  firebase
    .auth()
    .applyActionCode(oobCode)
    .then(function (data) {
      // change emailVerified for logged in User
      dispatch({
        type: actions.VERIFY_SUCCESS
      });
    })
    .catch(function (error) {
      dispatch({
        type: actions.VERIFY_FAIL,
        payload: error.message
      });
    });
};

export const ReadProfile = () => (
  dispatch,
  getState, {
    getFirebase,
    getFirestore
  }
) => {
  const firestore = getFirestore();
  let authorId = getState().firebase.auth.uid;
  if (authorId !== undefined) {
    firestore
      .collection("users")
      .doc(authorId)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document");
          dispatch({
            type: "READ_PROFILE_ERR",
            payload: "No such document"
          });
        } else {
          dispatch({
            type: "READ_PROFILE_SUCCESS",
            payload: doc.data()
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "READ_PROFILE_ERR",
          payload: err
        });
      });
  }
};

export const UpdateContact = (profile) => (
  dispatch,
  getState, {
    getFirebase,
    getFirestore
  }
) => {
  let authorId = getState().firebase.auth.uid;
  const firestore = getFirestore();
  firestore
    .collection("users")
    .doc(authorId)
    .update({
      phoneNumber: profile.phoneNumber !== undefined ? profile.phoneNumber : "",
      firstName: profile.firstName !== undefined ? profile.firstName : "",
      lastName: profile.lastName !== undefined ? profile.lastName : "",
      zipcode: profile.zipcode !== undefined ? profile.zipcode : "",
    })
    .then(() => {
      dispatch({
        type: "CHANGE_USER_CONTACT_SUCCESS"
      });
    })
    .catch((error) => {
      dispatch({
        type: "CHANGE_USER_CONTACT_FAIL",
        payload: error.message
      });
    });
};

export const UpdatePhoto = (photoUrl) => (
  dispatch,
  getState, {
    getFirestore
  }
) => {
  let authorId = getState().firebase.auth.uid;
  const firestore = getFirestore();
  firestore
    .collection("users")
    .doc(authorId)
    .update({
      photoUrl,
    })
    .then(() => {
      dispatch({
        type: "CHANGE_USER_CONTACT_SUCCESS"
      });
    })
    .catch((error) => {
      dispatch({
        type: "CHANGE_USER_CONTACT_FAIL",
        payload: error.message
      });
    });
};

export const UpdateEmail = (profile) => (
  dispatch,
  getState, {
    getFirebase,
    getFirestore
  }
) => {
  let authorId = getState().firebase.auth.uid;
  const firestore = getFirestore();
  const firebase = getFirebase();

  const user = firebase.auth().currentUser;
  user
    .updateEmail(profile.email)
    .then(() => {
      return firestore.collection("users").doc(authorId).update({
        email: profile.email,
      });
    })
    .then(() => {
      dispatch({
        type: "CHANGE_USER_CONTACT_SUCCESS"
      });
    })
    .catch((error) => {
      dispatch({
        type: "CHANGE_USER_CONTACT_FAIL",
        payload: error.message
      });
    });
};

export const UpdateNewMessage = (payload) => (
  dispatch,
  getState, {
    getFirebase,
    getFirestore
  }
) => {
  let authorId = getState().firebase.auth.uid;
  const firestore = getFirestore();

  if (authorId !== undefined) {
    firestore
      .collection("users")
      .doc(authorId)
      .set({
        ...payload
      }, {
        merge: true
      })
      .then(() => {
        dispatch({
          type: "CHANGE_NEW_MESSAGE_COUNT_SUCCESS"
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_NEW_MESSAGE_COUNT_FAIL",
          payload: error.message,
        });
      });
  }
};

export async function sendContactus(payload) {
  
  try {
    let result =   await axios.post("/api/sendGrid/contactus", {
      from: payload.from,
      subject: payload.subject,
      text: payload.text,
    });

    return({
      state: "success",
      data: result.data
    })
  } catch (error) {
    return({
      state: "error",
      message: error.message
    })
  }
  
}
export async function sendReport(payload) {
  
  try {
    let result =   await axios.post("/api/sendGrid/report", {
      from: payload.from,
      subject: payload.subject,
      text: payload.text,
    });

    return({
      state: "success",
      data: result.data
    })
  } catch (error) {
    return({
      state: "error",
      message: error.message
    })
  }
  
}
