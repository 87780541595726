import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Page } from 'components';
import { Header, SubjectList, CategorySegmentation } from './components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ReadCountsSubjects } from '../../actions/DashboardSubjectAnalyticsAction';
import * as data from '../../config/site_data.json';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    '& > *': {
      height: '100%'
    }
  }
}));

const DashboardSubjectAnalytics = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const analyticsData = useSelector(state => state.dashboardSubjectAnalytic);
  const subjects_data = data['subjects'];
  const [subjectList, setSubjectList] = useState([]);
  const [categoryCount, setCategoryCount] = useState(null)
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    dispatch({
      type: 'START_DASH_SUBJECT_ANALYTICS'
    });

    dispatch(ReadCountsSubjects());
  }, []);

  useEffect(() => {
    if (!analyticsData.searching) {
      getSubjectList();
    }
  }, [analyticsData]);

  const getSubjectList = () => {
    if (
      analyticsData.subjectList !== undefined &&
      analyticsData.subjectList !== null
    ) {
      let _categoryCount = {};
      let _totalCount = 0;

      const _subjectObject = analyticsData.subjectList;
      let _subjectList = [];
      for (let index = 0; index < subjects_data.length; index++) {
        const element = subjects_data[index];
        if (_subjectObject[element.subject] !== undefined) {
          _subjectList.push({
            category: element.category,
            subject: element.subject,
            count: _subjectObject[element.subject]
          });
          _totalCount = _totalCount + _subjectObject[element.subject];
          if (_categoryCount[element.category] === undefined)
            _categoryCount[element.category] = _subjectObject[element.subject];
          else
            _categoryCount[element.category] =
              _categoryCount[element.category] +
              parseInt(_subjectObject[element.subject]);
        }
      }
      let _searchList = [].concat(_subjectList).sort((a, b) => {
        if (a.count < b.count) {
          return 1;
        }
        if (a.count > b.count) {
          return -1;
        }
        return 0;
      });

      setSubjectList(_searchList);
      setTotalCount (_totalCount);
      setCategoryCount(_categoryCount);
    }
  };

  return (
    <Page className={classes.root} title="Analytics Dashboard">
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={8} xs={12}>
          <SubjectList subjectList={subjectList} totalCount={totalCount} />
        </Grid>

        <Grid item lg={4} xs={12}>
          <CategorySegmentation categoryCount={categoryCount} totalCount={totalCount} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardSubjectAnalytics;
