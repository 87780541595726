import React, { useState, useEffect } from "react";
import Popover from "@material-ui/core/Popover";
import { Backdrop, colors, makeStyles } from "@material-ui/core";

import YouTubeIcon from "@material-ui/icons/YouTube";
import isEmptyObject from "../../utils/isEmptyObject";
import validateYouTubeUrl from "../../utils/vaildateYoutubeUrl";
import YouTube from "react-youtube";
import { pink } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  youTube: {
    width: "500px",
    height:"400px"
  }
}));

const YouTubeVideo = (props) => {
  const { tutor } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [videoId, setVideoId] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [tipText, setTipText] = useState("");
  const [youTubeObj, setYouTubeObj] = useState(null);
  const [open2, setOpen2] = React.useState(false);
  const unmounted = React.useRef(false);

  useEffect(() => {
    // console.log(tutor)
    unmounted.current = false;
    if (tutor !== null && tutor !== undefined) {
      const intro_video = tutor.profile && tutor.profile.intro_video;
      if (!isEmptyObject(intro_video)) {
        const result = validateYouTubeUrl(intro_video);
        if (result && !unmounted.current) {
          var quality_key = 'mqdefault'; // Max quality
          setVideoId(result[2]);
          var _thumbnail = "http://img.youtube.com/vi/"+result[2]+"/"+quality_key+".jpg";
          setThumbnail(_thumbnail);
          setTipText("The tutor have a introduction video.");
        }
      }
    }
    return () => {
      unmounted.current = true;
    };
  }, [tutor]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = (event) => {
    event.stopPropagation();
    setOpen2(false);
    youTubeObj.pauseVideo()
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setOpen2(!open2);
    youTubeObj.playVideo()
  }

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
    },
  };
  const onReady = (event) => {
    setYouTubeObj(event.target);
    event.target.pauseVideo();
  };

  const open = Boolean(anchorEl);
  if (videoId !== null)
    return (
      <>
        <span style={{marginLeft:"10px"}}>
          <YouTubeIcon
            fontSize="large"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handleClose}
            onClick = {handleClick}
            style={{color:"red"}}
          ></YouTubeIcon>
        </span>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handleClose}
          disableRestoreFocus
          container={anchorEl && anchorEl.parentNode}
        >
          <img
            src={thumbnail}
            alt="img description"
            className="template-content tipso_style"
          />

        </Popover>
        <Backdrop
          className={classes.backdrop}
          open={open2}
          onClick={handleClose2}
        >
          <div className={classes.youTube}>

            <YouTube
              videoId={videoId}
              opts={opts}
              onReady={onReady}
            />
          </div>
        </Backdrop>
      </>
    );
  else return "";
};

export default YouTubeVideo;
