import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import overViewReducer from "./overViewReducer";
import studentReducer from "./studentReducer";
import postsReducer from "./postsReducer";
import tutorReducer from "./tutorReducer";
import DashboardAnalyticReducer from "./dashboardAnalyticReducer";
import DashboardSubjectAnalyticReducer from "./dashboardSubjectAnalyticReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  session: sessionReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  users : userReducer,
  overView : overViewReducer,
  students : studentReducer,
  posts : postsReducer,
  tutors : tutorReducer,
  dashboardAnalytic : DashboardAnalyticReducer,
  dashboardSubjectAnalytic : DashboardSubjectAnalyticReducer,
});

export default rootReducer;
