import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Page } from 'components';
import { Header } from './components';
import { useDispatch, useSelector } from 'react-redux';
import EmailSMSInfo from './components/EmailSMSInfo';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    '& > *': {
      height: '100%'
    }
  }
}));

let settings = [];

const EmailSetting = (props) => {

  const {match, history} = props;
  const {type} = match.params;
  const classes = useStyles();

  useFirestoreConnect([
    {
      collection: 'emailSetting',
      where: [['type', '==', type]],
      orderBy: ['show_order'],
    }
  ]);
  const emailSettings = useSelector(
    ({ firestore: { ordered } }) => ordered.emailSetting
  );

  if (isLoaded(emailSettings)) {
    let _settings = [];
    emailSettings.forEach(doc => {
      _settings.push({ ...doc, settingId: doc.id });
    });
    settings = _settings;
  }

  return (
    <Page className={classes.root} title="Analytics Dashboard">
      <Header type={type} />
      <Grid className={classes.container} container spacing={3}>
        {settings.map((item, index) => (
          <Grid item md={6} xs={12} key={index}>
            <EmailSMSInfo settingInfo={item} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default EmailSetting;
