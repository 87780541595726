import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';

import { GenericMoreButton } from 'components';
import { Chart } from './components';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 400
  }
}));

const UsersStats = props => {
  const {analyticsData,className, ...rest } = props;

  const [labels, setLabels] = useState([])
  const [data, setData] = useState({
    tutorSignup : [],
    tutorVisitor : []
  })
  // console.log(moment(startDate).format(), endDate);

  const classes = useStyles();

  useEffect(() => {
    if(!analyticsData.searching){
      setLabels(analyticsData.counts.ids);
      setData({
        tutorSignup : analyticsData.counts.tutorSignup,
        tutorVisitor : analyticsData.counts.tutorVisitor, 
        studentVisitor : analyticsData.counts.studentVisitor, 
        studentSignup : analyticsData.counts.studentSignup, 
      })
    }
   
  }, [analyticsData])
  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={<GenericMoreButton />}
        title="Users Stats"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={data}
              labels={labels}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

UsersStats.propTypes = {
  className: PropTypes.string
};

export default UsersStats;
