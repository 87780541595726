import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import axios from "axios";
import firebase from "firebase/app";
import ReactLoading from "react-loading";
import { v1 as uuid } from "uuid";


const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;

// Quill.register('modules/clipboard', PlainClipboard, true);

const QuillClipboard = Quill.import("modules/clipboard");

class Clipboard extends QuillClipboard {
  getMetaTagElements = (stringContent) => {
    const el = document.createElement("div");
    el.innerHTML = stringContent;
    return el.getElementsByTagName("meta");
  };

  async onPaste(e) {
    let clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = await clipboardData.getData("Text");

    const urlMatches = pastedData.match(/\b(http|https)?:\/\/\S+/gi) || [];
    if (urlMatches.length > 0) {
      e.preventDefault();
      urlMatches.forEach((link) => {
        axios
          .get(link)
          .then((payload) => {
            // let title, image, url, description;
            let title, image, url;
            for (let node of this.getMetaTagElements(payload)) {
              if (node.getAttribute("property") === "og:title") {
                title = node.getAttribute("content");
              }
              if (node.getAttribute("property") === "og:image") {
                image = node.getAttribute("content");
              }
              if (node.getAttribute("property") === "og:url") {
                url = node.getAttribute("content");
              }
              // if (node.getAttribute("property") === "og:description") {
              //     description = node.getAttribute("content");
              // }
            }

            const rendered = `<a href=${url} target="_blank"><div><img src=${image} alt=${title} width="20%"/><span>${title}</span></div></a>`;

            let range = this.quill.getSelection();
            let position = range ? range.index : 0;
            this.quill.pasteHTML(position, rendered, "silent");
            this.quill.setSelection(position + rendered.length);
          })
          .catch((error) => console.error(error));
      });
    } else {
      //console.log('when to use this') 보통 다른 곳에서  paste 한다음에  copy하면 이쪽 걸로 한다.
      super.onPaste(e);
    }
  }
}
Quill.register("modules/clipboard", Clipboard, true);

const BlockEmbed = Quill.import("blots/block/embed");

class ImageBlot extends BlockEmbed {
  static create(value) {
    const imgTag = super.create();
    imgTag.setAttribute("src", value.src);
    imgTag.setAttribute("alt", value.title);
    imgTag.setAttribute("max-width", "500px");
    return imgTag;
  }

  static value(node) {
    return { src: node.getAttribute("src"), alt: node.getAttribute("alt") };
  }
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
Quill.register(ImageBlot);

class FileBlot extends BlockEmbed {
  static create(value) {
    const prefixTag = document.createElement("span");
    prefixTag.innerText = "Attach File - ";

    const bTag = document.createElement("b");
    //위에 첨부파일 글자 옆에  파일 이름이 b 태그를 사용해서 나온다.
    bTag.innerText = value.title;

    const linkTag = document.createElement("a");
    linkTag.setAttribute("href", value.src);
    linkTag.setAttribute("target", "_blank");
    linkTag.setAttribute("className", "file-link-inner-post");
    linkTag.appendChild(bTag);
    //linkTag 이런식으로 나온다 <a href="btn_editPic@3x.png" target="_blank" classname="file-link-inner-post"><b>btn_editPic@3x.png</b></a>

    const node = super.create();
    node.appendChild(prefixTag);
    node.appendChild(linkTag);

    return node;
  }

  static value(node) {
    const linkTag = node.querySelector("a");
    return linkTag.getAttribute("href");
  }
}

FileBlot.blotName = "file";
FileBlot.tagName = "p";
FileBlot.className = "file-inner-post";
Quill.register(FileBlot);

class QuillEditor extends React.Component {
  htmlValue;
  placeholder;
  onEditorChange;

  constructor(props) {
    super(props);

    this.state = {
      editorHtml: __ISMSIE__ ? this.props.htmlValue : "",
      loading: false,
    };

    this.reactQuillRef = null;

    this.inputOpenImageRef = React.createRef();
    this.inputOpenFileRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      editorHtml: this.props.htmlValue,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (html) => {
    this.setState(
      {
        editorHtml: html,
      },
      () => {
        this.props.onEditorChange(this.state.editorHtml);
      }
    );
  };

  // I V F P들을  눌렀을떄 insertImage: this.imageHandler로 가서  거기서 inputOpenImageRef를 클릭 시킨다.
  imageHandler = () => {
    this.inputOpenImageRef.current.click();
  };

  fileHandler = () => {
    this.inputOpenFileRef.current.click();
  };

  uploadFile = (attach, fileType) => {
    const _uuid = uuid();
    const uploadTask = firebase
      .storage()
      .ref(`emailStuff/${_uuid}_${attach.name}`)
      .put(attach);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progrss function ....
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        if (progress === 100) {
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        // complete function ....
        firebase
          .storage()
          .ref("emailStuff")
          .child(`${_uuid}_${attach.name}`)
          .getDownloadURL()
          .then((url) => {
            if (this._isMounted) {
              const quill = this.reactQuillRef.getEditor();
              quill.focus();

              let range = quill.getSelection();
              let position = range ? range.index : 0;
              quill.insertEmbed(position, fileType, {
                src: url,
                title: attach.name,
              });
              quill.setSelection(position + 1);
            }
          }).catch((error)=>{
            console.log(error)
          });
      }
    );
  };

  insertImage = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      e.currentTarget &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      const file = e.currentTarget.files[0];
      this.setState({ loading: true });
      this.uploadFile(file, "image");
    }
  };

  insertFile = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      e.currentTarget &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      const file = e.currentTarget.files[0];
      this.setState({ loading: true });
      this.uploadFile(file, "file");
    }
  };

  render() {
    return (
      <div className="tc-rich-editor">
        <div id="toolbar">
          <select
            className="ql-header"
            defaultValue={""}
            onChange={(e) => e.persist()}
          >
            <option value="1" />
            <option value="2" />
            <option value="" />
          </select>
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <select className="ql-color">
            <option value="red" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#d0d1d2" />
            <option value="black" />
          </select>
          <select className="ql-align" />
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
          <button className="ql-code-block" />
          <button className="ql-blockquote" />
          <button className="ql-link" />
          <button className="ql-clean" />
          <button className="ql-insertImage">
            <InsertPhotoIcon />
          </button>
          <button className="ql-insertFile">
            <AttachFileIcon />
          </button>
        </div>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          id="tc-quill-editor"
          theme={"snow"}
          onChange={this.handleChange}
          modules={this.modules}
          formats={this.formats}
          value={this.state.editorHtml}
          placeholder={this.props.placeholder}
        />
        <input
          type="file"
          accept="image/*"
          ref={this.inputOpenImageRef}
          style={{ display: "none" }}
          onChange={this.insertImage}
        />
        <input
          type="file"
          accept="video/*"
          ref={this.inputOpenVideoRef}
          style={{ display: "none" }}
          onChange={this.insertVideo}
        />
        <input
          type="file"
          accept="*"
          ref={this.inputOpenFileRef}
          style={{ display: "none" }}
          onChange={this.insertFile}
        />
        <ReactLoading
          type={"bubbles"}
          color="red"
          height={this.state.loading === true ? 50 : 0}
          width={this.state.loading === true ? 50 : 0}
        />
      </div>
    );
  }

  modules = {
    toolbar: {
      container: "#toolbar",
      handlers: {
        insertImage: this.imageHandler,
        insertFile: this.fileHandler,
      },
    },
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "indent",
    "image",
    "file",
    "link",
    "code-block",
    "video",
    "blockquote",
    "clean",
    "script",
    "color",
    "formula",
  ];
}

export default QuillEditor;
