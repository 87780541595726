const initState = {
  subjectList: null,
  searching: false,
  error: ''
};

const DashboardAnalyticReducer = (state = initState, action) => {
  switch (action.type) {
    case 'START_DASH_SUBJECT_ANALYTICS':
      return {
        ...state,
        searching: true,
        subjectList: null,
        error: ''
      };

    case 'DASH_SUBJECT_ANALYTICS_READ_COUNT_USERS_SUCCESS':
      return {
        ...state,
        error: '',
        searching: false,
        subjectList: action.payload
      };

    case 'DASH_SUBJECT_ANALYTICS_READ_COUNT_USERS__ERROR':
      return {
        ...state,
        subjectList: null,
        error: action.payload
      };

  

    default:
        return state;
  }
};


export default DashboardAnalyticReducer;