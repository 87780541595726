const initState = {
  studentList: [],
  studentUnsetList: [],
  selectedStudent: null,
  searching: false,
  error: '',
  searchText: '',
  search2Text: '',
  filter: null,
  pagination: null,
  pagination2: null,
  actionType: ''
};

const studentReducer = (state = initState, action) => {
  switch (action.type) {
    case 'INIT_STUDENT':
      return {
        studentList: [],
        selectedStudent: null,
        searching: false,
        error: '',
        searchText: '',
        filter: null,
        pagination: null,
        actionType: ''
      };
    case 'INIT_STUDENT_ACTION':
      return {
        ...state,
        error: '',
        actionType: ''
      };

    case 'START_STUDENT_ACTION':
      return {
        ...state,
        actionType: 'start_student_action',
        error: ''
      };

    case 'START_STUDENT_LIST':
      return {
        ...state,
        searching: true,
        studentList: [],
        error: ''
      };

    case 'START_STUDENT_UNSET_LIST':
      return {
        ...state,
        searching: true,
        studentUnsetList: [],
        error: '',
      };
    case 'START_STUDENT_DETAIL':
      return {
        ...state,
        searching: true,
        selectedStudent: null,
        error: ''
      };

    case 'READ_STUDENT_LIST_SUCCESS':
      return {
        ...state,
        error: '',
        searching: false,
        studentList: action.payload
      };

    case 'READ_STUDENT_LIST_ERROR':
      return {
        ...state,
        studentList: [],
        searching: false,
        error: action.payload
      };

    case 'READ_STUDENT_LIST_UNSET_SUCCESS':
      return {
        ...state,
        error: '',
        searching: false,
        studentUnsetList: action.payload
      };

    case 'READ_STUDENT_LIST_UNSET_ERROR':
      return {
        ...state,
        studentUnsetList: [],
        searching: false,
        error: action.payload
      };

    case 'DELETE_STUDENT_LIST_SUCCESS':
      return {
        ...state,
        error: '',
        actionType: 'delete_students'
      };

    case 'DELETE_STUDENT_LIST_ERROR':
      return {
        ...state,
        actionType: 'delete_students',
        error: action.payload
      };

    case 'STUDENT_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.payload
      };

    case 'STUDENT_SEARCH2_TEXT':
      return {
        ...state,
        search2Text: action.payload
      };
    case 'STUDENT_SEARCH_PAGINATION':
      return {
        ...state,
        pagination: action.payload
      };
    case 'STUDENT_SEARCH_PAGINATION2':
      return {
        ...state,
        pagination2: action.payload
      };
    case 'STUDENT_FILTER':
      return {
        ...state,
        filter: action.payload
      };

    default:
      return state;
  }
};

export default studentReducer;
