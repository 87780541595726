import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  CardMedia
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';
import { Label } from 'components';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    paddingBottom: 0
  },
  author: {
    display: 'flex',
    alignItems: 'center',
    color: '#2979ff'
  },
  content: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  description: {
    padding: theme.spacing(2, 3, 1, 3),
    color: '#8e8e8e'
  },
  tags: {
    padding: theme.spacing(0, 1, 1, 1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  actionDiv: {
    display: 'flex',
    justifyContent: 'end'
  },
  details: {
    padding: theme.spacing(1, 3)
  },
  media: {
    height: 200
  }
}));

const MAX_STR_LENGTH = 200;

const PostCard = props => {
  const { post, deletePost, className, ...rest } = props;
  const router = useRouter();
  const classes = useStyles();

  function getText(html) {
    var divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    let planText = divContainer.textContent || divContainer.innerText || '';
    if (planText.length > MAX_STR_LENGTH) {
      planText = planText.substring(0, MAX_STR_LENGTH) + '...';
    }
    return planText;
  }

  const handleDelete = () => {
    if (window.confirm('Are you sure to delete this post?')) {
      deletePost(post.postId);
    }
  };

  const handleClick = () => {
    router.history.push(`/postView/${post.postId}`);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          className={classes.media}
          image={
            post.coverPhotoUrl === ''
              ? '/images/post_cover.png'
              : post.coverPhotoUrl
          }
          title="post cover image"
        />
        <CardContent className={classes.content}>
          <div className={classes.tags}>
            {post.tags.map((tag, index) => (
              <Label key={index}>{tag}</Label>
            ))}
          </div>
          <Typography gutterBottom variant="h4" component="h2">
            {post.title}
          </Typography>
          <div className={classes.author}>
            <PersonIcon color="secondary" fontSize="small" />{' '}
            <span>{post.author}</span>
            <EventIcon
              color="secondary"
              fontSize="small"
              style={{ margin: '0 3px 0 20px' }}
            />{' '}
            <span> {moment(post.createDate).format('MMMM Do YYYY')} </span>
          </div>
          <div className={classes.description}>
            {getText(post.contents)}
            {/* <div
              data-gramm="false"
              dangerouslySetInnerHTML={{ __html: post.contents }}></div> */}
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actionDiv}>
        <Button
          className={classes.learnMoreButton}
          component={RouterLink}
          size="small"
          to={`/postEdit/${post.postId}`}>
          Edit
        </Button>
        <Button
          className={classes.learnMoreButton}
          size="small"
          onClick={handleDelete}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

PostCard.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired
};

export default PostCard;
