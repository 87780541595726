const initState = {
  postList: [],
  selectedPost: null,
  searching: false,
  error: '',
  searchText: '',
  filter: null,
  pagination: null,
  sortBy: '',
  actionType: ''
};

const postsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'INIT_POSTS_ACTION':
      return {
        ...state,
        error: '',
        actionType: ''
      };

    case 'START_POSTS_ACTION':
      return {
        ...state,
        actionType: 'start_posts_action',
        error: ''
      };

    case 'START_POSTS_LIST':
      return {
        ...state,
        searching: true,
        postList: [],
        error: ''
      };


    case 'START_POSTS_DETAIL':
      return {
        ...state,
        searching: true,
        selectedTutor: null,
        error: ''
      };

    case 'READ_POST_LIST_SUCCESS':
      return {
        ...state,
        error: '',
        searching: false,
        postList: action.payload
      };

    case 'READ_POST_LIST_ERROR':
      return {
        ...state,
        postList: [],
        searching: false,
        error: action.payload
      };

    case 'DELETE_POSTS_LIST_SUCCESS':
      return {
        ...state,
        error: '',
        actionType: 'delete_tutor'
      };

    case 'DELETE_POSTS_LIST_ERROR':
      return {
        ...state,
        actionType: 'delete_tutor',
        error: action.payload
      };

    case 'POSTS_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.payload
      };

    case 'POSTS_SEARCH_PAGINATION':
      return {
        ...state,
        pagination: action.payload
      };

    case 'POSTS_ROW_PER_PAGE':
      return {
        ...state,
        rowPerPage: action.payload
      };

    case 'POSTS_SORT_BY':
      return {
        ...state,
        sortBy: action.payload,
        pagination: 0
      };

    case 'POSTS_FILTER':
      return {
        ...state,
        filter: action.payload
      };

    default:
      return state;
  }
};

export default postsReducer;
