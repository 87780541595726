import moment from 'moment';
import axios from 'axios';

export const ReadCountsSubjects = payload => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    let authorId = getState().firebase.auth.uid;
    let subjectList = null;


    if (authorId !== undefined) {
      try {
       
        const snapshot2 = await firestore.collection('tutorInfor').get();
        if (snapshot2.empty) {
          subjectList = null;
        } else {

          let subjects = {total:0};
          snapshot2.forEach(doc => {
            const _tutor = doc.data();
            if (_tutor.subjects !== undefined) {
              subjects["total"] = subjects["total"] + _tutor.subjects.length;
              for (let index = 0; index < _tutor.subjects.length; index++) {
                const element = _tutor.subjects[index];
                if (subjects[element] === undefined) subjects[element] = 1;
                else subjects[element] = subjects[element] + 1;
              }
            }
          });
          subjectList = subjects;
        }

        dispatch({
          type: 'DASH_SUBJECT_ANALYTICS_READ_COUNT_USERS_SUCCESS',
          payload: subjectList
        });
      } catch (err) {
        dispatch({
          type: 'DASH_SUBJECT_ANALYTICS_READ_COUNT_USERS__ERROR',
          payload: err.message
        });
      }
    }
  };
};

