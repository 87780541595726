import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { colors } from '@material-ui/core';
import { GenericMoreButton } from 'components';
import { Chart } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  chartContainer: {
    padding: theme.spacing(3)
  },
  chart: {
    height: 281
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}));

const UsersSegmentation = props => {
  const { analyticsData, className, ...rest } = props;

  const classes = useStyles();
  const [earnings, setEarnings] = useState([]);

  useEffect(() => {
    if(!analyticsData.searching){
    setEarnings([
      {
        id: 1,
        label: 'Tutors',
        value: (analyticsData.counts.totalTutors / (analyticsData.counts.totalTutors + analyticsData.counts.totalStudents) * 100).toFixed(1),
        count: analyticsData.counts.totalTutors ,
        color: colors.blue[500]
      },
      {
        id: 2,
        label: 'Students',
        value: (analyticsData.counts.totalStudents / (analyticsData.counts.totalTutors + analyticsData.counts.totalStudents) * 100).toFixed(1),
        count: analyticsData.counts.totalStudents ,
        color: colors.green[500]
      },
    ]);
  }
    
  }, [analyticsData]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Users Segmentation"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.chartContainer}>
          <Chart
            className={classes.chart}
            data={earnings}
          />
        </div>
        <Divider />
        <div className={classes.statsContainer}>
          {earnings.map(earning => (
            <div
              className={classes.statsItem}
              key={earning.id}
            >
              <Typography
                align="center"
                component="h6"
                gutterBottom
                variant="overline"
              >
                {earning.label}
              </Typography>
              <Typography
                align="center"
                variant="h3"
              >
                {earning.count}
              </Typography>
              <Typography
                align="center"
                variant="h4"
              >
                {earning.value} %
              </Typography>
           
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

UsersSegmentation.propTypes = {
  className: PropTypes.string
};

export default UsersSegmentation;
