
export const ReadCounts = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    let authorId = getState().firebase.auth.uid;
    let counts = {
      totalTutors: 0,
      totalStudents: 0,
      todayTutorVisitor: 0,
      todayStudentVisitor: 0,
      todayTutorSignup: 0,
      todayStudentSignup: 0,
      yesterdayTutorVisitor: 0,
      yesterdayStudentVisitor: 0,
      yesterdayTutorSignup: 0,
      yesterdayStudentSignup: 0,
      onlineTutors: 0,
      onlineStudents: 0
    };

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    let usersInfoDocKeyOfToday =
      today.getFullYear().toString() +
      '-' +
      today.getMonth().toString() +
      '-' +
      today.getDate().toString();

    let usersInfoDocKeyOfYesterday =
      yesterday.getFullYear().toString() +
      '-' +
      yesterday.getMonth().toString() +
      '-' +
      yesterday.getDate().toString();

    if (authorId !== undefined) {
      try {
        const snapshot = await firestore
          .collection('users')
          .where('accountType', '==', 'Student')
          .get();
        if (snapshot.empty) {
          counts.totalStudents = 0;
        } else {
          counts.totalStudents = snapshot.docs.length; //studentDocs.length;
        }

        const snapshot2 = await firestore.collection('users')
        .where('accountType', '==', 'Tutor')
        .get();;
        if (snapshot2.empty) {
          counts.totalTutors = 0;
        } else {
          counts.totalTutors = snapshot2.docs.length; // tutorDocs.length;
        }
      
        const snapshotOnlineStudent = await firestore
          .collection('users')
          .where('accountType', '==', 'Student')
          .where('state', 'in', ['online','away'])
          .get();
        if (snapshotOnlineStudent.empty) {
          counts.onlineStudents = 0;
        } else {
          counts.onlineStudents = snapshotOnlineStudent.docs.length; //studentDocs.length;
        }

        const snapshotOnlineTutors = await firestore.collection('users')
        .where('accountType', '==', 'Tutor')
        .where('state', 'in', ['online','away'])
        .get();;
        if (snapshotOnlineTutors.empty) {
          counts.onlineTutors = 0;
        } else {
          counts.onlineTutors = snapshotOnlineTutors.docs.length; // tutorDocs.length;
        }

        const usersInfoToday = await firestore
          .collection('usersStatus')
          .doc(usersInfoDocKeyOfToday)
          .get();
        if (usersInfoToday.exists) {
          counts.todayTutorVisitor =
            usersInfoToday.data().TutorVisitor === undefined
              ? 0
              : usersInfoToday.data().TutorVisitor;
          counts.todayStudentVisitor =
            usersInfoToday.data().StudentVisitor === undefined
              ? 0
              : usersInfoToday.data().StudentVisitor;
          counts.todayTutorSignup =
            usersInfoToday.data().TutorSignUp === undefined
              ? 0
              : usersInfoToday.data().TutorSignUp;
          counts.todayStudentSignup =
            usersInfoToday.data().StudentSignUp === undefined
              ? 0
              : usersInfoToday.data().StudentSignUp;
        }

        const usersInfoYesterday = await firestore
          .collection('usersStatus')
          .doc(usersInfoDocKeyOfYesterday)
          .get();
        if (usersInfoYesterday.exists) {
          counts.yesterdayTutorVisitor =
            usersInfoYesterday.data().TutorVisitor === undefined
              ? 0
              : usersInfoYesterday.data().TutorVisitor;
          counts.yesterdayStudentVisitor =
            usersInfoYesterday.data().StudentVisitor === undefined
              ? 0
              : usersInfoYesterday.data().StudentVisitor;
          counts.yesterdayTutorSignup =
            usersInfoYesterday.data().TutorSignUp === undefined
              ? 0
              : usersInfoYesterday.data().TutorSignUp;
          counts.yesterdayStudentSignup =
            usersInfoYesterday.data().StudentSignUp === undefined
              ? 0
              : usersInfoYesterday.data().StudentSignUp;
        }

        dispatch({
          type: 'READ_COUNTS_SUCCESS',
          payload: counts
        });
      } catch (err) {
        dispatch({ type: 'READ_COUNTS_ERROR', payload: err.message });
      }
    }
  };
};
