import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableRow,
  TableCell,
  Typography,
  Button,
  colors,
  TableBody
} from '@material-ui/core';
import CircularProgress from './components/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  inner: {
    minWidth: 700
  },
  details: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    marginRight: theme.spacing(2),
    flexShrink: 0,
    height: 56,
    width: 56
  },
  subscriptions: {
    fontWeight: theme.typography.fontWeightMedium
  },
  price: {
    whiteSpace: 'nowrap'
  },
  value: {
    color: colors.green[600],
    fontWeight: theme.typography.fontWeightMedium
  },
  conversion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  conversionStats: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));


const SubjectList = props => {
  const { subjectList, totalCount, className, ...rest } = props;

  const classes = useStyles();

 

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader  title={`Subject List (Total Counts: ${totalCount})`} />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableBody>
                {subjectList.map((subject,index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      <div className={classes.details}>
                          <Typography variant="h6">{index + 1}</Typography>
                      </div>
                    </TableCell>
                    
                    <TableCell>
                      <div className={classes.details}>
                        <div>
                          <Typography variant="h6">{subject.subject}</Typography>
                          <Typography variant="subtitle2">
                            <span className={classes.subscriptions}>
                              {subject.category}
                            </span>{' '}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">Count</Typography>
                      <Typography className={classes.price} variant="subtitle2">
                        <span className={classes.value}>
                          {subject.count}
                        </span>{' '}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <div className={classes.conversion}>
                        <div className={classes.conversionStats}>
                          <Typography align="right" variant="h6">
                            {(subject.count/totalCount * 100).toFixed(2)}%
                          </Typography>
                          
                        </div>
                        <CircularProgress color="secondary" value={subject.count/totalCount * 100} />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
     
      </CardActions>
    </Card>
  );
};

SubjectList.propTypes = {
  className: PropTypes.string
};

export default SubjectList;
