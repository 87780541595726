const initState = {
  tutorList: [],
  tutorListUnset: [],
  selectedTutor: null,
  searching: false,
  error: '',
  searchText: '',
  search2Text: '',
  filter: null,
  pagination: null,
  pagination2: null,
  rowPerPage: 10,
  sortBy:"",
  actionType: ''
};

const tutorReducer = (state = initState, action) => {
  switch (action.type) {
    case 'INIT_TUTOR_ACTION':
      return {
        ...state,
        error: '',
        actionType: ''
      };

    case 'START_TUTOR_ACTION':
      return {
        ...state,
        actionType: 'start_tutor_action',
        error: ''
      };
    case 'START_TUTOR_LIST':
      return {
        ...state,
        searching: true,
        tutorList: [],
        error: '',
        // searchText: '',
        // filter: null,
        // pagination: null,
        // rowPerPage: 10,
      };
    
      case 'RESET_TUTOR_LIST':
      return {
        ...state,
        searching: true,
        tutorList: [],
         searchText: '',
        filter: null,
        pagination: null,
        rowPerPage: 10,
        sortBy: "",
        error: '',
      };

    case 'START_TUTOR_UNSET_LIST':
      return {
        ...state,
        searching: true,
        tutorListUnset: [],
        error: '',
      };
    case 'START_TUTOR_DETAIL':
      return {
        ...state,
        searching: true,
        selectedTutor: null,
        error: ''
      };

    case 'READ_TUTOR_LIST_SUCCESS':
      return {
        ...state,
        error: '',
        searching: false,
        tutorList: action.payload
      };

    case 'READ_TUTOR_LIST_ERROR':
      return {
        ...state,
        tutorList: [],
        searching: false,
        error: action.payload
      };

    case 'READ_TUTOR_LIST_UNSET_SUCCESS':
      return {
        ...state,
        error: '',
        searching: false,
        tutorListUnset: action.payload
      };

    case 'READ_TUTOR_LIST_UNSET_ERROR':
      return {
        ...state,
        tutorListUnset: [],
        searching: false,
        error: action.payload
      };

    case 'DELETE_TUTOR_LIST_SUCCESS':
      return {
        ...state,
        error: '',
        actionType: 'delete_tutor'
      };

    case 'DELETE_TUTOR_LIST_ERROR':
      return {
        ...state,
        actionType: 'delete_tutor',
        error: action.payload
      };

    case 'TUTOR_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.payload
      };
    case 'TUTOR_SEARCH2_TEXT':
      return {
        ...state,
        search2Text: action.payload
      };
    case 'TUTOR_SEARCH_PAGINATION':
      return {
        ...state,
        pagination: action.payload
      };
    case 'TUTOR_SEARCH_PAGINATION2':
      return {
        ...state,
        pagination2: action.payload
      };
  
    case 'TUTOR_ROW_PER_PAGE':
      return {
        ...state,
        rowPerPage: action.payload
      };
      
    case 'TUTOR_SORT_BY':
      return {
        ...state,
        sortBy: action.payload,
        pagination: 0
      };

    case 'TUTOR_FILTER':
      return {
        ...state,
        filter: action.payload
      };

    default:
      return state;
  }
};

export default tutorReducer;
