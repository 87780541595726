import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TextareaAutosize,
  Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { SettingInfoEdit } from './components';
import { useFirebase } from 'react-redux-firebase';
import QuillBubble from 'components/QuillEditor/QuillBubble';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  fullWidth: {
    padding: 5,
    marginTop: 0,
    width: '100%'
  }
}));

const EmailSMSInfo = props => {
  const { settingInfo, className, ...rest } = props;
  const firebase = useFirebase();
  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleSave = formState => {
    settingEmail(formState);
    setOpenEdit(false);
  };

  async function settingEmail(formState) {
    try {
      let db = firebase.firestore();
      await db
        .collection('emailSetting')
        .doc(settingInfo.settingId)
        .update(formState);
    } catch (error) {}
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={settingInfo.description}
        action={
          <Tooltip title="Edit Email setting">
            <Button onClick={handleEditOpen}>
              <EditIcon className={classes.buttonIcon} />
              Edit
            </Button>
          </Tooltip>
        }
      />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow selected>
              <TableCell
                style={{ width: '120px', paddingLeft: '20px' }}
                padding="none">
                Send Email
              </TableCell>
              <TableCell padding="none">
                <Checkbox checked={settingInfo.enableEmail} color="primary" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ width: '120px', paddingLeft: '20px' }}
                padding="normal">
                Subject
              </TableCell>
              <TableCell padding="normal">{settingInfo.subject}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell
                style={{ width: '120px', paddingLeft: '20px' }}
                padding="none">
                Contents
              </TableCell>
              <TableCell padding="none">
                <QuillBubble contents={settingInfo.contents} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ width: '120px', paddingLeft: '20px' }}
                padding="none">
                Send SMS
              </TableCell>
              <TableCell padding="none">
                <Checkbox checked={settingInfo.enableSMS} color="primary" />
              </TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell
                style={{ width: '120px', paddingLeft: '20px' }}
                padding="normal">
                SMS Text
              </TableCell>
              <TableCell padding="normal">
                {settingInfo.SMS.split('\n').map((item, idx) => {
                  return (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <SettingInfoEdit
          settingInfo={settingInfo}
          onClose={handleEditClose}
          open={openEdit}
          onSave={handleSave}
        />
      </CardContent>
    </Card>
  );
};

export default EmailSMSInfo;
