const initState = {
  tutors: [],
  lessons: [],
  students: [],
  selTutor: null,
  selStudent: null,
  searching: false,
  error: ''
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SEARCH_START':
      return {
        ...state,
        searching: true,
        selStudent: null,
        students: [],
        error: ''
      };

    case 'READ_STUDENTS_SUCCESS':
      return {
        ...state,
        error: '',
        selStudent: null,
        searching: false,
        students: action.payload
      };

    case 'READ_STUDENTS_ERROR':
      return {
        ...state,
        students: [],
        error: action.payload
      };

    default:
        return state;
  }
};


export default userReducer;